import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <p>{`January 2024 has been an exciting month. After years of negotiation, the SEC has finally approved a set of exchange-traded products (ETPs) tracking the spot price of cryptocurrencies. This is a significant step, as it recognizes cryptos as an investible asset class. But do we need yet another asset class?`}</p>
    <p>{`In the interest of diversification, the answer is resounding yes – provided that the asset also comes with the prospects of long-term growth. While we only have limited information on the latter, it is easier to see why cryptos might have a low or even negative correlation to the classic asset classes. The key here is that cryptos form a decentralized system, that is not controlled by any central authority. This is a good reason to believe that they may behave fundamentally differently in times where governments intervene, especially through their monetary policy.`}</p>
    <p>{`Of course, ‘different’ is not synonymous with ‘better’ – but it also doesn’t have to be. The central idea of tactical asset allocation is to adjust allocations as the markets change. Consequently, any asset that potentially provides positive returns when others don’t, should improve our ability to outperform the markets.`}</p>
    <p>{`In the past, cryptocurrencies have been seen more as the asset of choice for high-stakes gamblers than one suitable for serious investors with a long-term plan to reach financial goals. And there was certainly some truth to that, given that the infrastructure including marketplaces and wallets is unregulated. Here is where the SEC’s approval of spot ETPs will change matters: it is now possible to invest in cryptos, while enjoying the liquidity of regulated secondary marketplaces, and conveniently holding all asset classes in the same brokerage account. And while this certainly does not eliminate all doubts, it is certainly a big step forward.`}</p>
    <p>{`Coming from stock market ETFs, investors are accustomed to safely assume that the various products are almost identical. This is not the case for cryptos. Here, the field is significantly more diverse, and the various products fall in three main categories:`}</p>
    <ul>
      <li parentName="ul">{`ETPs investing directly in cryptocurrencies`}</li>
      <li parentName="ul">{`ETPs investing in cryptocurrency futures contracts`}</li>
      <li parentName="ul">{`ETPs investing in businesses involved with cryptocurrencies`}</li>
    </ul>
    <p>{`Only the first category, ETPs investing directly in cryptocurrencies, is new. The other two categories have been around for several years and will continue to do so. Let’s have a look at these in more detail.`}</p>
    <h2>{`Crypto Spot ETPs`}</h2>
    <p>{`This category is newly approved by the SEC in January 2024. The contenders directly invest in cryptocurrencies, with the assets typically held in cold storage:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Inception Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Expense Ratio`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`AUM`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://etfs.grayscale.com/gbtc"
            }}>{`GBTC`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Grayscale Bitcoin Trust ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`September 2013`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$21B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ishares.com/us/products/333011/ishares-bitcoin-trust"
            }}>{`IBIT`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iShares Bitcoin Trust`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1.8B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.fidelity.com/etfs/fbtc"
            }}>{`FBTC`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fidelity Wise Origin Bitcoin Fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1.4B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://bitwiseinvestments.com/crypto-funds/bitw"
            }}>{`BITW`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bitwise 10 Crypto Index Fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`November 2017`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$706M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://bitbetf.com/"
            }}>{`BITB`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bitwise Bitcoin ETF Trust`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.20%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$650M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://ark-funds.com/funds/arkb/"
            }}>{`ARKB`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARK 21Shares Bitcoin ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.21%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$605M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.invesco.com/us/en/etf/galaxy-bitcoin-btco.html"
            }}>{`BTCO`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invesco Galaxy Bitcoin ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.39%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$290M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.vaneck.com/us/en/investments/bitcoin-trust-hodl/overview/"
            }}>{`HODL`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VanEck Bitcoin Trust`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$121M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://valkyrieinvest.com/brrr/"
            }}>{`BRRR`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valkyrie Bitcoin Fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$111M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.franklintempleton.com/investments/options/exchange-traded-funds/products/39639/SINGLCLASS/franklin-bitcoin-etf/EZBC"
            }}>{`EZBC`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Franklin Bitcoin ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.19%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$63M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.wisdomtree.com/investments/etfs/crypto/btcw"
            }}>{`BTCW`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wisdom Tree Bitcoin Fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.30%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$12.8M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.simplify.us/etfs/spbc-simplify-us-equity-plus-gbtc-etf"
            }}>{`SPBC`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplify US Equity plus GBTC ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`May 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.72%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$9.7M`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Curious investors will notice that, even though spot crypto ETPs have only been approved by the SEC in January 2024, some of these funds have an inception date significantly earlier. These funds were started as private placements for accredited investors, and with the SEC’s recent approval have been uplisted as ETPs.`}</p>
    <p>{`Most of the ETPs are still ramping up their assets under management. For the purpose of tactical asset allocation, market liquidity is key - which is related to market capitalization. And while the daily `}<a parentName="p" {...{
        "href": "/articles/market-liquidity/"
      }}>{`trading volume has much less relevance for ETPs`}</a>{` than it has for individual stocks, it remains an important figure to watch. We believe that a fund suitable for the implementation of tactical strategies should have at least a few hundred million dollars under management.`}</p>
    <p>{`To get there, it seems that the new contenders are pricing their products aggressively. While the oldest funds from Greyscale and Simplify charge a management fee of 1.5% and 0.72% respectively, the new contenders are shooting for 0.25%, and most of them are even willing to waive their fees for the next few months to accelerate the influx of capital.`}</p>
    <p>{`Other than the managemenaret fee, we can assume that ETPs tracking the same crypto should be interchangeable – provided they have sufficient AUM and liquidity to keep their tracking errors low.`}</p>
    <h2>{`Crypto Futures ETPs`}</h2>
    <p>{`Unlike the previous category, these ETPs do not invest directly in cryptocurrencies. Instead, the contenders invest in futures contracts, tracking the price of the underlying crypto:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Inception Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Expense Ratio`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`AUM`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.proshares.com/our-etfs/strategic/bito"
            }}>{`BITO`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProShares Bitcoin Strategy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`October 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.95%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1.47B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.volatilityshares.com/bitx"
            }}>{`BITX`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Volatility Shares 2x Bitcoin Strategy ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`June 2023`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.85%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$205M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.vaneck.com/us/en/investments/bitcoin-strategy-etf-xbtf/overview/"
            }}>{`XBTF`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VanEck Bitcoin Strategy ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.66%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$44M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://valkyrie-funds.com/btf/"
            }}>{`BTF`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valkyrie Bitcoin and Ether Strategy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`October 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.2%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$35M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://hashdex-etfs.com/defi"
            }}>{`DEFI`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hashdex Bitcoin Futures ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`September 2022`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.90%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$17M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.globalxetfs.com/funds/bits/"
            }}>{`BITS`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Global X Blockchain & Bitcoin Strategy ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`November 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.65%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$16M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://bitcetf.com/"
            }}>{`BITC`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bitwise Bitcoin Strategy Optimum Roll ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`March 2023`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.92%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$5M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://btopetf.com/"
            }}>{`BTOP`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bitwise Bitcoin and Ether Equal Weight Strategy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`September 2023`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.85%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$4.2M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.proshares.com/our-etfs/strategic/beth"
            }}>{`BETH`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProShares Bitcoin & Ether Market Cap Weight Strategy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`October 2023`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.95%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$4.2M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.proshares.com/our-etfs/strategic/bete"
            }}>{`BETE`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProShares Bitcoin & Ether Equal Weight Strategy ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`October 2023`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.95%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$2.7M`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Futures contracts are traded at the CBOE, which solves the concerns over crypto marketplaces. However, investing in futures contracts comes with its own set of difficulties. First, futures contracts have a limited lifetime, as they describe an exchange of goods at a fixed point in the future. Because of that, these ETPs must continually `}<a parentName="p" {...{
        "href": "https://www.cmegroup.com/education/courses/introduction-to-futures/understanding-futures-expiration-contract-roll.html"
      }}>{`roll their holdings from the current month into the next month`}</a>{`. This requires ongoing oversight and trading, which is the reason why many of these funds are called 'strategies' – and come with a management fee north of 0.80%.`}</p>
    <p>{`But there is more to rolling than just the management effort. Because crypto futures are typically in `}<a parentName="p" {...{
        "href": "https://www.cmegroup.com/education/courses/introduction-to-ferrous-metals/what-is-contango-and-backwardation.html"
      }}>{`contango`}</a>{`, meaning the next month’s contract is more expensive than the current contract, these products suffer from a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Roll_yield"
      }}>{`negative roll yield`}</a>{`, which inevitably leads to a significant drag on their performance. Consequently, investors will only partially participate in movements in the crypto spot price. Only under rare circumstances, when the futures contracts go into `}<a parentName="p" {...{
        "href": "https://www.cmegroup.com/education/courses/introduction-to-ferrous-metals/what-is-contango-and-backwardation.html"
      }}>{`backwardation`}</a>{`, will these ETPs outperform the spot market.`}</p>
    <p>{`These properties make this type of funds unattractive for investors, and especially tactical asset allocation. It seems that these funds are a dying breed that will soon be superseded by the spot ETPs.`}</p>
    <h2>{`ETFs of crypto-related stocks`}</h2>
    <p>{`The last category of ETPs does not invest in cryptocurrencies at all. Instead, these products invest in companies generating (at least part of) their revenue from crypto-related business activities:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Inception Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Expense Ratio`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`AUM`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://ark-funds.com/funds/arkw/"
            }}>{`ARKW`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARK Next Gen Internet ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`September 2014`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.88%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1.8B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://bitqetf.com/"
            }}>{`BITQ`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bitwise Crypto Industry Innovators ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`May 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.85%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$111M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://valkyrie-funds.com/wgmi/"
            }}>{`WGMI`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valkyrie Bitcoin Miners ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`February 2022`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.75%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$62M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.schwabassetmanagement.com/products/stce"
            }}>{`STCE`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Schwab Crypto Thematic ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`August 2022`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$20M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.invesco.com/us/financial-products/etfs/product-detail?audienceType=Investor&productId=ETF-SATO"
            }}>{`SATO`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invesco Alerian Galaxy Cropto Economy ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`October 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.60%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$7.4M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.invesco.com/us/financial-products/etfs/product-detail?audienceType=Advisor&productId=ETF-BLKC"
            }}>{`BLKC`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invesco Alerian Galaxy Blockchain Users and Decentralized Commerce ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`October 2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.61%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$3.4M`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The investment thesis for these products is very simple: if cryptocurrencies are doing well, businesses engaged in developing blockchain technology, mining cryptos, trading cryptos, or providing enabling technologies, should also do well. This is similar to investing in companies that mine gold, instead of shiny metal. And because these funds are typically diversified across 20 or more publicly traded companies, all subject to the SEC’s oversight, these funds should be reasonably well protected against single-company failures, and pump-and-dump schemes.`}</p>
    <p>{`However, the investment thesis might not work out that way. Because cryptocurrencies are a decentralized system, it is anything but clear that business profits are related to the market price of cryptos. Further, the decentralized scheme likely results in an ecosystem tilted toward smaller firms - which might not be easily investable by these funds. Of particular concern might be that the funds’ liquidity might be much higher than that of the underlying stocks, leading to unusually large tracking errors between the funds NAV, and the market price, in case of unidirectional market pressure.`}</p>
    <p>{`This structural issue likely requires funds to invest in companies with only marginal interest in cryptocurrencies, e.g., companies providing GPUs, to find enough market capitalization and liquidity. These issues are like those observed with ETFs investing in small caps – only magnified. As a result, this type of thematic ETF might be on borrowed time, unless the crypto industry grows substantially. It seems that ARK has recognized that early on, which is why their fund has a significantly broader scope, a smorgasbord of tech companies, and by no means limited to cryptocurrencies. This is especially true, as the management fees for the ETFs are quite high, given their passive nature.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ef5bc6925f3632b342e83745fc14d2ef/21b4d/btc-spx-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACiklEQVQoz22Ty0uUURjGT/dNqy6IbQyioGgR9i/UJrBamVBmo2ljBEUZRCYipUs3Fm0yCexKVCOBUERUIIlmzvTNxfF+m2RuzozjzJzv9ovvjLaIHnh5n3PO+z68nHMeEU+tEPm9RCaTQdd1TNP8G7phsg7btgFL8e+TWU52zVDbM080LZmM5nk5mCCZziEMw+B/UP3A51CW+IqBZdkMTuUYW5Ic65xFVPkQNRr7myfYfiVEyfUQqZUcwpnKgWnZqsmybaRRVJuJS3ZeC3GwZYLG3gibLwXY4g4gLmhsdQfUWtRqSvhA8zixZNqZUKewJvAvKrrmVLG46FciGxsCbGoIsPVSUOVNDUG2uYOIuoASXIolEc69tHpinH4wx9hSgdmEpNUTZe+tMOKsD+HSlJjKLh+i1lsMl5NHEXWjiOpR9tz0k1ktIKQuufxskZKbAU7cn+LUw2n23QlyqC1EeXuYI+1hyjvCipe3jyt+5O4E5fcmOOpER5jDbWEquqbJ5goI70KKN1qU/mCS1944r0YdnsDjW+ZDKK32PVqCPi3Be21Z5f5Qgne+JG9/xejzpvF4U3wMJckXJGJ4Jk3j83lE5TCi4SfCPYKoGqLsdpCR+SyVj6YRZ4YQ9SOI88OI6m+4X8xS82SWshYfon4AcW6I3U0ay5ksAtsklrFwPY6woT6IqPZzvHMO/6JUD/N1LEfpjXF2XQvj6olQ0x1hOWuTzln4F3V2XB2jtClM16ck+WwKUZASQy9gmzq9A1G6v0TJ5Qtg66zmJbYpGY9keDoQBVNiGMU9Q5dgSX5MZViIrwJSGUM4UxiGiWU5Lih+H4c7LrFtC9O0wDKUSxzunBmOk9Zqilivt/kDKTfG/CQRc3AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "btc spx cumulative",
            "title": "btc spx cumulative",
            "src": "/static/ef5bc6925f3632b342e83745fc14d2ef/c1b63/btc-spx-cumulative.png",
            "srcSet": ["/static/ef5bc6925f3632b342e83745fc14d2ef/5a46d/btc-spx-cumulative.png 300w", "/static/ef5bc6925f3632b342e83745fc14d2ef/0a47e/btc-spx-cumulative.png 600w", "/static/ef5bc6925f3632b342e83745fc14d2ef/c1b63/btc-spx-cumulative.png 1200w", "/static/ef5bc6925f3632b342e83745fc14d2ef/21b4d/btc-spx-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Bitcoin as a Portfolio Component`}</h2>
    <p>{`In summary, it seems that crypto spot ETPs are a big step forward, in adding cryptocurrencies as a new asset class to everyday investor’s portfolios. Now, this raises the question of how we can possibly trade this asset class. We can characterize Bitcoin as follows:`}</p>
    <ul>
      <li parentName="ul">{`Bitcoin offers tremendous return opportunities, which dwarf the returns achieved with stock-market investments.`}</li>
      <li parentName="ul">{`However, these returns come with tremendous volatility. In fact, Bitcoin’s risk-adjusted-returns are lower than those seen with stock-market investments.`}</li>
      <li parentName="ul">{`Bitcoin seems to go through major cycles of about four years length. During the downturn of these cycles, its value collapses by more than 70%.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a39908dcaf05b7c60da6ba7f2239e4b7/21b4d/btc-spx-monte-carlo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACQklEQVQoz3WT3UsUURiHT2RSQVAgEQkKXUV4X3ThVf9AN0VS5vduiZVimtGXN0kfhHddRBeFfVCwGAVCFBhdZIGpOzu742rWLurqzqy7ue662+7ME3NmtRXyhYf3cOac83vP+5sjbg3OU+pSKe/U2NcRYG97gIPXgpwfmGdwbBlldpVIIkc6a7IxrCLsyJNKpRAdr8KIkwpbmv2IRtWhzoc4o0hK3X7KLwc5dGOa6rs/qXk0S+frBfqGDB4OL/H8628pPDKTRjcMRJcnhDitsP1cgBKXX7LN5afUHZBsbSkI1auIsz5ErS3mk3vWOTFB2SWN+QXDrjCEOPUd0aIgmrwOjTaKQ70XUed1coP9XaHE7WNHq8qutgBl7Rp7Lmoc7ZshasQR/e8Xqeqdovr+D47cCXL4nkZ1f4DjjydpejnN7Y9hBsYieNQF3mqLDAUX+TATZfiXzuewzsiczreIgRI1yGSziG5PiN0XJjlwZYqK7iAVPRqVV/1U9mjs79L4NJUsNN0sMsEsIi+xyJNMJhFtL0KImlGEawLRPO5cuUF1aFRRZjPyuFzewjTZgGX9yzaxWAzR+yYim7yzNSAdtY2wDRI1Xo49+OXUZllYm7D+E1kW8Xgc8W48XnBOQdQqheyj6uY06lymsJj/hj2dXs2SzWblgbLCTCbDsy8G7qdhXE/CXPfMMTgaYzmVAesPqfQqKysrpNNpubEY2wR9KYGuR0kkEnJOWJvJ2202LXK5nHwBtrC91jTN9eva47VYG/8FBkm96pKhGQoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "btc spx monte carlo",
            "title": "btc spx monte carlo",
            "src": "/static/a39908dcaf05b7c60da6ba7f2239e4b7/c1b63/btc-spx-monte-carlo.png",
            "srcSet": ["/static/a39908dcaf05b7c60da6ba7f2239e4b7/5a46d/btc-spx-monte-carlo.png 300w", "/static/a39908dcaf05b7c60da6ba7f2239e4b7/0a47e/btc-spx-monte-carlo.png 600w", "/static/a39908dcaf05b7c60da6ba7f2239e4b7/c1b63/btc-spx-monte-carlo.png 1200w", "/static/a39908dcaf05b7c60da6ba7f2239e4b7/21b4d/btc-spx-monte-carlo.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Monte Carlo simulation shows that despite these characteristics, Bitcoin may outperform the stock market with high confidence after a little over seven years. And the long-term returns are tremendous, reaching about 30% at the fifth percentile. However, investors should be prepared to lose 90% of their value in the interim, and it might take more than ten years to recover from such losses.`}</p>
    <p>{`Before getting too greedy, it is worth noting that Bitcoin’s time to recover from major drawdowns, as determined by our Monte Carlo simulation, is on the same order as the price history available to us (starting in May 2013). This tells us that we really do not have enough data to know for sure.`}</p>
    <p>{`In summary, we see the following requirements for successful investments in Bitcoin:`}</p>
    <ul>
      <li parentName="ul">{`Volatility is the factor limiting the maximum advisable exposure to Bitcoins. Given its buy-and-hold characteristics, we feel that an allocation to Bitcoin should not exceed 5% of the total portfolio value.`}</li>
      <li parentName="ul">{`The minimum investment horizon is driven by volatility. As it seems, investors should target ten or more years for investments in Bitcoin.`}</li>
      <li parentName="ul">{`Any investment strategy should focus on reducing the risk, rather than outperforming buy-and-hold. Such a strategy would allow to increase the exposure to Bitcoin, and substantially boost portfolio returns.`}</li>
      <li parentName="ul">{`We should carefully monitor future returns, and check if these are in-line with the historical data used to develop the strategy.`}</li>
    </ul>
    <h2>{`Next Steps`}</h2>
    <p>{`We have started looking into a trend-following strategy for Bitcoin and have seen some preliminary encouraging results. We hope to roll out this new strategy later in the year and combine it with other strategies into a well-balanced portfolio. We don’t need to rush this step, though. Instead, it is worth waiting for the newly approved crypto-ETPs to ramp up their AUM, and for the market to determine the winners and losers in this new niche. We expect this to take a few months, giving us time to complete our research. Stay tuned!`}</p>
    <ButtonPrimary text="Explore Our Premium Portfolios" to="/portfolios/?tag=premium" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      